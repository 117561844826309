import AulasEletivosIndexPage from "@/Pages/AulasEletivos/index.vue";
import NewEditAulaEletivoPage from "@/Pages/AulasEletivos/NewEditAulaEletivoPage.vue";
import AulasAguardandoConfirmacao from "@/Pages/AulasEletivos/AulasAguardandoConfirmacao.vue";
import LancamentoDeFrequenciaPage from "@/Pages/AulasEletivos/LancamentoDeFrequenciaPage.vue";

export default [
  {
    path: "/gestoes-de-eletivos/:gestaoDeEletivosId/aulas-eletivas/create",
    name: "aulasEletivos.create",
    component: NewEditAulaEletivoPage,
    props: { editing: false },
  },
  {
    path: "/gestoes-de-eletivos/:gestaoDeEletivosId/aulas-eletivas",
    name: "aulasEletivos",
    component: AulasEletivosIndexPage,
    props: true,
  },
  {
    path: "/gestoes-de-eletivos/aulas-aguardando-confirmacao",
    name: "aulasEletivos.aguardandoConfirmacao",
    component: AulasAguardandoConfirmacao,
  },
  {
    path: "/gestoes-de-eletivos/:gestaoDeEletivosId/aulas-eletivas/edit/:aula_id",
    name: "aulasEletivos.edit",
    component: NewEditAulaEletivoPage,
    props: { editing: true },
  },
  {
    path: "/gestoes-de-eletivos/:gestaoDeEletivosId/aulas-eletivas/:aula_id/frequencia",
    name: "aulasEletivos.frequencia",
    component: LancamentoDeFrequenciaPage,
    props: true,
  },
];
