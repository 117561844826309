import Axios from "@/plugins/Axios";

export class PlanosAnuaisService {
  async buscarPlanoAnualInfanil(gestaoDeAulaId) {
    const response = await Axios().get(`planos/plano-anual-infantil/${gestaoDeAulaId}`);
    return response.data;
  }

  async createOrUpdateInfantil(plano) {
    const response = await Axios().post("planos/criar-plano-anual-infantil", {
      plano,
    });
    return response.data;
  }

  async buscarPlanoAnual(gestaoDeAulaId) {
    const response = await Axios().get(`planos/plano-anual/${gestaoDeAulaId}`);
    return response.data;
  }

  async createOrUpdate(plano) {
    const response = await Axios().post("planos/criar-plano-anual", {
      plano,
    });
    return response.data;
  }
}
export default new PlanosAnuaisService();
