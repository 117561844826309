<template>
  <div>
    <v-app id="mainApp">
      <v-main>
        <v-toast />
        <router-view />
        <v-btn
          v-if="showHelpIcon"
          style="position: fixed"
          class="custom-position"
          color="info"
          dark
          absolute
          bottom
          right
          fab
          @click="() => navigate()"
        >
          <v-icon>mdi-help-circle</v-icon>
        </v-btn>
      </v-main>
    </v-app>
    <e-modal />
    <e-loader />
  </div>
</template>

<script>
import { VToast } from "vuetify-snackbar-toast";
import { mapActions } from "vuex";

export default {
  name: "App",
  components: { VToast },
  methods: {
    ...mapActions("Auth", ["verificarForcaDaSenha"]),
    navigate() {
      window.open("https://cajutec.tawk.help", "_blank");
    },
  },
  data() {
    return {
      noAuthRoutesNames: ["resetPassword", "login"],
      showHelpIcon: false,
    };
  },
  mounted() {
    document.title = process.env.VUE_APP_PAGE_TITLE;
    this.$loaderService.loadBasicDataIfNeeded();
    // this.$services.authService.verificarForcaDaSenha();
    this.verificarForcaDaSenha();
  },
  watch: {
    $route(to, from) {
      this.showHelpIcon = !this.noAuthRoutesNames.includes(this.$router.currentRoute.name);
    },
  },
};
</script>
<style scoped>
#mainApp {
  background-color: #efefef;
}
.custom-position {
  margin-bottom: 110px;
}
</style>
