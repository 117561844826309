// import MapCollection from "collections/map";
import Vue from "vue";
import gestoesDeEletivosService from "@/Services/GestoesDeEletivosService";
import $handleError from "@/plugins/handleError";
// import gestaoDeEletivos from "../../router/gestaoDeEletivos";

const state = () => ({
  gestoesDeEletivos: [],
  carregandoGestoesDeAulas: false,
  gestoesDeAulasLoaded: false,
  gestoesDeAulasList: [],
});
const mutations = {
  setGestoesDeEletivos(state, gestoesDeEletivos) {
    const gestoesDeEletivosMap = new MapCollection();
    gestoesDeEletivos.map((gestaoDeEletivo) =>
      gestoesDeEletivosMap.set(gestaoDeEletivo.id, gestaoDeEletivo)
    );
    state.gestoesDeEletivos = gestoesDeEletivosMap;
    Vue.set(state, "gestoesDeEletivos", gestoesDeEletivosMap);
    Vue.set(state, "gestoesDeEletivosList", Array.from(gestoesDeEletivosMap.values()));
    Vue.set(state, "gestoesDeEletivosLoaded", true);
    // state.gestoesDeAulasLoaded = true;
    // state.gestoesDeAulasList = Array.from(state.gestoesDeAulas.values());
    // state = {...state, gestoesDeAulas: gestoesDeAulasMap, gestoesDeAulasLoaded: true}
  },

  // adicionei
  setterGestaoDeEletivo(state, gestaoDeEletivo) {
    // state.gestaoDeAula = gestaoDeAula;
    Vue.set(state, "gestaoDeEletivo", gestaoDeEletivo);
  },
  setCarregandoGestoesDeEletivos(state, newValue) {
    state.carregandoGestoesDeEletivos = newValue;
  },
  adicionarGestaoDeAula(state, gestaoDeAula) {
    state.gestoesDeAulas.set(gestaoDeAula.id, gestaoDeAula);
    Vue.set(state, "gestoesDeAulas", state.gestoesDeAulas);
    Vue.set(state, "gestoesDeAulasList", Array.from(state.gestoesDeAulas.values()));
  },
  atualizarGestaoDeAula(state, gestaoDeAula) {
    state.gestoesDeAulas.set(gestaoDeAula.id, gestaoDeAula);
    Vue.set(state, "gestoesDeAulas", state.gestoesDeAulas);
    Vue.set(state, "gestoesDeAulasList", Array.from(state.gestoesDeAulas.values()));
  },
  deletarGestaoDeAula(state, gestaoDeAulaId) {
    state.gestoesDeAulas.delete(gestaoDeAulaId);
    Vue.set(state, "gestoesDeAulas", state.gestoesDeAulas);
    Vue.set(state, "gestoesDeAulasList", Array.from(state.gestoesDeAulas.values()));
  },
};
const actions = {
  async loadGestoesDeEletivos({ commit }, options = {}) {
    // commit("setCarregandoGestoesDeAulas", true);
    try {
      const gestoesDeEletivosList = await gestoesDeEletivosService.syncAll(options.force);
      // commit("setGestoesDeAulas", gestoesDeAulasList);
    } catch (error) {
      $handleError(error);
    }
    // commit("setCarregandoGestoesDeAulas", false);
  },
  // adicionei
  async setGestaoDeAula({ commit }, gestaoDeAula) {
    // commit("setterGestaoDeAula", gestaoDeAula);
  },
};
const getters = {
  gestoesDeEletivos: (state) => state.gestoesDeEletivos,
  carregandoGestoesDeEletivos: (state) => state.carregandoGestoesDeEletivos,
  gestoesDeEletivosLoaded: (state) => state.gestoesDeEletivosLoaded,
  gestoesDeEletivosList: (state) => state.gestoesDeEletivosList,
  // adicionei
  gestaoDeEletivos: (state) => state.gestaoDeEletivo,
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
