var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-center"},[(
      _vm.$constants.situacoesMatriculaCondition[
        _vm.removerEspaco(_vm.matricula.situacao ? _vm.matricula.situacao : '')
      ]
    )?[_c('v-currency-field',{ref:"input",style:({
        'min-width': '60px !important',
        'max-width': '60px !important',
      }),attrs:{"value":_vm.value || null,"hide-details":"","allow-negative":false,"dense":"","min":_vm.min,"decimal-length":1,"max":_vm.max,"outlined":true,"disabled":true}})]:[_c('v-currency-field',{ref:"input",style:({
        'min-width': '60px !important',
        'max-width': '60px !important',
      }),attrs:{"value":_vm.value || null,"hide-details":"","allow-negative":false,"dense":"","min":_vm.min,"max":_vm.max,"decimal-length":1,"outlined":true,"disabled":_vm.disabled},on:{"input":_vm.handleInput}}),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.value === null && _vm.value !== undefined && !_vm.disabled),expression:"value === null && value !== undefined && !disabled"}],staticClass:"red lighten-5 ml-2",attrs:{"icon":"","small":"","color":"error"},on:{"click":_vm.emitNull}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa fa-close")])],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }