<template>
  <v-col cols="12" md="3" sm="12">
    <v-card :loading="loading" class="overflow-y-auto" max-height="400" height="180">
      <template slot="progress">
        <v-progress-linear color="indigo" indeterminate></v-progress-linear>
      </template>

      <v-card-text>
        <div class="text-h6 font-weight-black">Deficientes ({{ qtd }}%)</div>
        <span class="font-weight-black"> </span>
      </v-card-text>

      <apexchart type="bar" height="200px" :options="chartOptions" :series="series"></apexchart>

      <!-- <v-sheet color="light">
      </v-sheet> -->
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "DeficientesCard",
  mounted() {
    this.getDeficientes();
  },
  data() {
    return {
      qtd: 0,
      loading: true,
      series: this.series,

      chartOptions: {
        chart: {
          type: "bar",
          offsetY: -30,
          toolbar: {
            show: false,
          },
        },

        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "100%",
            barHeight: "100%",
            endingShape: "rounded",
            dataLabels: {
              show: true,
              position: "bottom",
            },
          },
        },

        colors: [
          "#FF0000", // Vermelho
          "#0000FF", // Azul
          "#008000", // Verde
          "#FFFF00", // Amarelo
          "#800080", // Roxo
          "#FFA500", // Laranja
          "#FFC0CB", // Rosa
          "#A52A2A", // Marrom
          "#008080", // Teal
          "#800000", // Maroon
          "#000080", // Navy
          "#E6E6FA", // Lavender
          "#C0C0C0", // Prata
          "#FFD700", // Ouro
          "#000000", // Preto
          "#FFFFFF", // Branco
          "#808080", // Cinza
          "#FF00FF", // Magenta
          "#40E0D0", // Turquesa
          "#808000", // Oliva
          "#F5F5DC", // Bege
          "#FF7F50", // Coral
          "#00FFFF", // Ciano
          "#FF00FF", // Fúcsia
          "#4B0082", // Índigo
          "#F0E68C", // Khaki
          "#00FF00", // Lima
          "#DDA0DD", // Plum
          "#FA8072", // Salmão
          "#D2B48C", // Tan
        ],

        dataLabels: {
          enabled: false,
          dropShadow: {
            enabled: false,
          },
        },

        grid: {
          show: false,
        },

        tooltip: {
          x: {
            show: false,
          },
        },

        stroke: {
          show: false,
          width: 0,
          colors: ["transparent"],
        },

        yaxis: {
          show: false,
        },

        legend: {
          show: true,
        },

        xaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          },
          categories: [1, 2, 3, 4],
        },

        fill: {
          opacity: 1,
        },
      },
    };
  },
  methods: {
    async getDeficientes() {
      const response = await this.$services.alunosService.deficientes();

      // eslint-disable-next-line
      this.qtd = response.data[1].toString().substr(0, 4);

      const data = Object.entries(response.data[0]);
      const categories = [];
      const series = [];

      // eslint-disable-next-line
      data.map((d) => {
        categories.push(d[0]);
      });

      // eslint-disable-next-line
      data.map((d) => {
        series.push({ name: d[0], data: [d[1]] });
      });

      this.loading = false;
      this.series = [];
      this.series = [...series];

      this.chartOptions = {
        series: [...series],
        xaxis: {
          labels: {
            show: false,
          },
          // categories: [...categories],
        },
      };
    },
  },
};
</script>

<style scoped>
div.chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
