import { render, staticRenderFns } from "./TurmasCard.vue?vue&type=template&id=43859eb8&scoped=true"
import script from "./TurmasCard.vue?vue&type=script&lang=js"
export * from "./TurmasCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43859eb8",
  null
  
)

export default component.exports