import MapCollection from "collections/map";
import Vue from "vue";
import gestoesDeAulasService from "@/Services/GestoesDeAulasService";
import $handleError from "@/plugins/handleError";

const state = () => ({
  gestoesDeAulas: [],
  carregandoGestoesDeAulas: false,
  gestoesDeAulasLoaded: false,
  gestoesDeAulasList: [],
});
const mutations = {
  setGestoesDeAulas(state, gestoesDeAulas) {
    const gestoesDeAulasMap = new MapCollection();
    gestoesDeAulas.map((gestaoDeAula) => gestoesDeAulasMap.set(gestaoDeAula.id, gestaoDeAula));
    state.gestoesDeAulas = gestoesDeAulasMap;
    Vue.set(state, "gestoesDeAulas", gestoesDeAulasMap);
    Vue.set(state, "gestoesDeAulasList", Array.from(gestoesDeAulasMap.values()));
    Vue.set(state, "gestoesDeAulasLoaded", true);
    // state.gestoesDeAulasLoaded = true;
    // state.gestoesDeAulasList = Array.from(state.gestoesDeAulas.values());
    // state = {...state, gestoesDeAulas: gestoesDeAulasMap, gestoesDeAulasLoaded: true}
  },

  // adicionei
  setterGestaoDeAula(state, gestaoDeAula) {
    // state.gestaoDeAula = gestaoDeAula;
    Vue.set(state, "gestaoDeAula", gestaoDeAula);
  },
  setCarregandoGestoesDeAulas(state, newValue) {
    state.carregandoGestoesDeAulas = newValue;
  },
  adicionarGestaoDeAula(state, gestaoDeAula) {
    state.gestoesDeAulas.set(gestaoDeAula.id, gestaoDeAula);
    Vue.set(state, "gestoesDeAulas", state.gestoesDeAulas);
    Vue.set(state, "gestoesDeAulasList", Array.from(state.gestoesDeAulas.values()));
  },
  atualizarGestaoDeAula(state, gestaoDeAula) {
    state.gestoesDeAulas.set(gestaoDeAula.id, gestaoDeAula);
    Vue.set(state, "gestoesDeAulas", state.gestoesDeAulas);
    Vue.set(state, "gestoesDeAulasList", Array.from(state.gestoesDeAulas.values()));
  },
  deletarGestaoDeAula(state, gestaoDeAulaId) {
    state.gestoesDeAulas.delete(gestaoDeAulaId);
    Vue.set(state, "gestoesDeAulas", state.gestoesDeAulas);
    Vue.set(state, "gestoesDeAulasList", Array.from(state.gestoesDeAulas.values()));
  },
};
const actions = {
  async loadGestoesDeAulas({ commit }, options = {}) {
    // commit("setCarregandoGestoesDeAulas", true);
    try {
      const gestoesDeAulasList = await gestoesDeAulasService.syncAll(options.force);
      // commit("setGestoesDeAulas", gestoesDeAulasList);
    } catch (error) {
      $handleError(error);
    }
    // commit("setCarregandoGestoesDeAulas", false);
  },
  // adicionei
  async setGestaoDeAula({ commit }, gestaoDeAula) {
    // commit("setterGestaoDeAula", gestaoDeAula);
  },
};
const getters = {
  gestoesDeAulas: (state) => state.gestoesDeAulas,
  carregandoGestoesDeAulas: (state) => state.carregandoGestoesDeAulas,
  gestoesDeAulasLoaded: (state) => state.gestoesDeAulasLoaded,
  gestoesDeAulasList: (state) => state.gestoesDeAulasList,
  // adicionei
  gestaoDeAula: (state) => state.gestaoDeAula,
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
