<template>
  <div class="template-wrapper">
    <div class="main-content">
      <v-app-bar elevate-on-scroll width="100%" color="primary" style="overflow: hidden">
        <v-list color="transparent" max-width="260px" class="pa-0"> </v-list>
      </v-app-bar>

      <v-row v-if="form.aluno" class="mb-6" justify="center" no-gutters>
        <v-col lg="6" md="6" sm="8">
          <v-card class="pa-4 mt-3" outlined tile>
            <v-row class="d-flex justify-center mb-4">
              <span class="text-h5 mt-3">
                OLÁ, {{ form.aluno.nomecompleto ? form.aluno.nomecompleto : "- - -" }}
              </span>
            </v-row>

            <v-divider></v-divider>

            <v-row class="mb-6 d-flex justify-center" no-gutters>
              <v-avatar class="mt-12" size="150px">
                <img alt="" :src="imagem" />
              </v-avatar>
            </v-row>

            <form class="mt-5">
              <!-- <span class="text-h6">Matrícula</span> -->
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="form.turma.franquia.descricao"
                    label="Escola"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="form.turma.franquia.cidade"
                    label="Cidade"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="form.aluno.estadualidade"
                    label="Estado"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="form.turma.descricao"
                    label="Turma"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    readonly
                    v-model="form.turma.series[0].descricao"
                    label="Serie"
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- <v-row>
                <v-col>
                  <v-chip color="teal" text-color="white">
                    {{ form.situacao ? form.situacao : "Cursando" }}
                  </v-chip>
                </v-col>
              </v-row> -->

              <div class="d-block pa-2 teal white--text text-center mt-5">
                {{ form.situacao ? form.situacao : "Cursando" }}
              </div>
            </form>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.loadInfor();
    // this.$Tawk.$setOnLoadFunction(() => this.$Tawk.$hideWidget());
  },

  data() {
    return {
      form: {},
      imagem: null,
    };
  },

  methods: {
    async loadInfor() {
      const matricula = parseInt(this.$route.params.matricula_id, 10);
      const data = await this.$services.matriculasService.informacoes(matricula);
      this.form = { ...data.data };
      this.imagem = await this.verImage(this.form.id);
    },

    async verImage(matricula_id) {
      return this.$services.matriculasService.downloadImage(matricula_id);
    },
  },
};
</script>
<style></style>
