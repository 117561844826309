import MapCollection from "collections/map";
import Vue from "vue";
import aulasService from "@/Services/AulasEletivosService";
import $handleError from "@/plugins/handleError";

const state = () => ({
  aulas: [],
  carregandoAulas: false,
  aulasLoaded: false,
  aulasList: [],
});
const mutations = {
  setAulas(state, aulas) {
    const aulasMap = new MapCollection();
    aulas.map((aula) => aulasMap.set(aula.id, aula));
    Vue.set(state, "aulas", aulasMap);
    Vue.set(state, "aulasList", Array.from(aulasMap.values()));
    Vue.set(state, "aulasLoaded", true);
    // state = {...state, aulas: aulasMap, aulasLoaded: true}
  },
  setCarregandoAulas(state, newValue) {
    state.carregandoAulas = newValue;
  },
  adicionarAula(state, aula) {
    state.aulas.set(aula.id, aula);
    Vue.set(state, "aulas", state.aulas);
    Vue.set(state, "aulasList", Array.from(state.aulas.values()));
  },
  atualizarAula(state, aula) {
    state.aulas.set(aula.id, aula);
    Vue.set(state, "aulas", state.aulas);
    Vue.set(state, "aulasList", Array.from(state.aulas.values()));
  },
  deletarAula(state, aulaId) {
    state.aulas.delete(aulaId);
    Vue.set(state, "aulas", state.aulas);
    Vue.set(state, "aulasList", Array.from(state.aulas.values()));
  },
};
const actions = {
  async loadAulas({ commit }, options = {}) {
    commit("setCarregandoAulas", true);
    try {
      const aulasList = await aulasService.syncAll(options.force);
      commit("setAulas", aulasList);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoAulas", false);
  },
};
const getters = {
  aulas: (state) => state.aulas,
  carregandoAulas: (state) => state.carregandoAulas,
  aulasLoaded: (state) => state.aulasLoaded,
  aulasList: (state) => state.aulasList,
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
