import Model from "./Model";
import { $constants } from "../plugins/constants";
import { dateFormat } from "../plugins/filters";

export default class AulaEletiva extends Model {
  constructor(aula) {
    super();
    Object.assign(this, aula);
    this.descricaoSituacao = this.getDescricaoSituacao();
    this.color = this.getColor();
    this.data_formatada = this.getDataFormatada();
  }

  getDescricaoSituacao() {
    if (!this.situacao) return "Aguardando confirmação";
    return this.situacao;
  }

  getColor() {
    let tipo_cor = "success";

    $constants.situacoesAulas.forEach((i) => {
      if (i.descricao === this.descricaoSituacao) {
        return (tipo_cor = i.cor);
      }
      return tipo_cor;
    });
    return tipo_cor;
  }

  getDataFormatada() {
    return this.data_aula_lancada ? dateFormat(this.data_aula_lancada) : null;
  }
}
