import GestaoDeEletivosIndexPage from "@/Pages/GestaoDeEletivos/index.vue";
import NewEditGestaoDeEletivosPage from "@/Pages/GestaoDeEletivos/NewEditGestaoDeEletivosPage.vue";
// import Geral from "@/Pages/GestaoDeAulas/Geral.vue";
// import NewEditGestaoDeAulaPageGeral from "@/Pages/GestaoDeAulas/NewEditGestaoDeAulaPageGeral.vue";
// import PlanosBimestrais from "@/Pages/GestaoDeAulas/planosBimestrais.vue";
// import PlanosBimestraisInfantil from "@/Pages/GestaoDeAulas/planosBimestraisInfantil.vue";
// import PlanosMensais from "@/Pages/GestaoDeAulas/planosMensais.vue";
// import PlanosMensaisInfantil from "@/Pages/GestaoDeAulas/planosMensaisInfantil.vue";
// import planoAnualIfantil from "@/Pages/GestaoDeAulas/planoAnualIfantil.vue";
// import planoAnual from "@/Pages/GestaoDeAulas/planoAnual.vue";
// // Métricas
// import MetricasDaTurma from "../Pages/GestaoDeAulas/dashboard.vue";

export default [
  {
    path: "/gestoes-de-eletivos/",
    name: "gestoesDeEletivos",
    component: GestaoDeEletivosIndexPage,
  },
  {
    path: "/gestoes-de-eletivos/create",
    name: "gestoesDeEletivos.create",
    component: NewEditGestaoDeEletivosPage,
    props: { editing: false },
  },
  {
    path: "/gestoes-de-eletivos/edit/:gestaoDeEletivosId",
    name: "gestoesDeEletivos.edit",
    component: NewEditGestaoDeEletivosPage,
    props: { editing: true },
  },
  // {
  //   path: "/gestoes-de-aulas/geral",
  //   name: "gestoesDeAulas.geral",
  //   component: Geral,
  // },
  // {
  //   path: "/gestoes-de-aulas/create",
  //   name: "gestoesDeAulas.createGeral",
  //   component: NewEditGestaoDeAulaPageGeral,
  //   props: { editing: false },
  // },
  // {
  //   path: "/gestoes-de-aulas/geral/edit/:gestaoDeAulasId",
  //   name: "gestoesDeAulas.editGeral",
  //   component: NewEditGestaoDeAulaPageGeral,
  //   props: { editing: true },
  // },
  // {
  //   path: "/gestoes-de-aulas/:gestaoDeAulaId/plano-bimestral",
  //   name: "gestoesDeAulas.planoBimestralCreate",
  //   component: PlanosBimestrais,
  //   props: { editing: false },
  // },
  // {
  //   path: "/gestoes-de-aulas/:gestaoDeAulaId/plano-bimestral-infantil",
  //   name: "gestoesDeAulas.newEditplanoBimestralInfantil",
  //   component: PlanosBimestraisInfantil,
  //   props: { editing: false },
  // },
  // {
  //   path: "/gestoes-de-aulas/:gestaoDeAulaId/plano-anual",
  //   name: "gestoesDeAulas.newEditplanoAnual",
  //   component: planoAnual,
  //   props: { editing: false },
  // },
  // {
  //   path: "/gestoes-de-aulas/:gestaoDeAulaId/plano-anual-infantil",
  //   name: "gestoesDeAulas.newEditplanoAnualInfantil",
  //   component: planoAnualIfantil,
  //   props: { editing: false },
  // },
  // {
  //   path: "/gestoes-de-aulas/:gestaoDeAulaId/plano-mensal",
  //   name: "gestoesDeAulas.newEditplanoMensal",
  //   component: PlanosMensais,
  //   props: { editing: false },
  // },
  // {
  //   path: "/gestoes-de-aulas/:gestaoDeAulaId/plano-mensal-infantil",
  //   name: "gestoesDeAulas.newEditplanoMensalInfantil",
  //   component: PlanosMensaisInfantil,
  //   props: { editing: false },
  // },
  // // Métricas
  // {
  //   path: "/gestoes-de-aulas/:id/turma/metricas/:gestao",
  //   name: "gestoesDeAulas.metricasTurma",
  //   component: MetricasDaTurma,
  // },
];
