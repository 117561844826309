import Axios from "@/plugins/Axios";
import Aula from "@/Models/AulasEletiva";
import axios from "axios";

export class AulasEletivosService {
  async syncAll({ turma_id, disciplina_id }) {
    const response = await Axios().get("aulas-eletivas", { params: { turma_id, disciplina_id } });
    const aulas = response.data.map((aula) => new Aula(aula));
    return aulas;
  }

  async listarAulasAguardandoConfirmacao() {
    const response = await Axios().get("aulas-eletivas/listar-aulas-aguardando-confirmacao");
    const aulas = response.data.data.map((aula) => new Aula(aula));
    return aulas;
  }

  async listarAulasAguardandoConfirmacaoPaginate(pageNumber, query) {
    const response = await Axios().get(
      `aulas-eletivas/listar-aulas-aguardando-confirmacao?page=${pageNumber}&query=${query}`
    );
    // const aulas = response.data.data.map((aula) => new Aula(aula));
    return response.data;
  }

  // async countBotoesHomer() {
  //   const response = await Axios().get("aulas-eletivas/count-botoes-homer");
  //   return response.data;
  // }

  async registrarPresenca(aula, { matricula_id, presente }) {
    const response = await Axios().post(`aulas-eletivas/${aula.aula.id}/registrar-presenca`, {
      matricula_id,
      presente,
    });
    return response.data;
  }

  async justificarFalta(
    aula,
    { observacao = "", file = null, matriculaSelect = null, justificativa_id = null }
  ) {
    const formData = new FormData();
    formData.append("observacao", observacao);
    formData.append("file", file);
    formData.append("matricula_id", matriculaSelect);
    formData.append("justificativa_id", justificativa_id);

    Axios().interceptors.request.use((config) => {
      config.headers["Content-Type"] = "multipart/form-data";
      return config;
    });

    const response = await Axios().post(
      `aulas-eletivas/${aula.aula.id}/justificar-falta`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  }

  async criar(aula) {
    const response = await Axios().post("aulas-eletivas", aula);
    const novaAula = new Aula(response.data);
    return novaAula;
  }

  async getAulaComRelacoes(aula_id) {
    const response = await Axios().get(`aulas-eletivas/${aula_id}`);
    return new Aula(response.data);
  }

  async getAula(aula_id) {
    const response = await Axios().get(`aulas-eletivas/get-aula-eletivas/${aula_id}`);
    return new Aula(response.data);
  }

  async atualizar(aula) {
    const response = await Axios().put(`aulas-eletivas/${aula.id}`, aula);
    const aulaAtualizada = response.data;
    return new Aula(aulaAtualizada);
  }

  async atualizarRegistroNotifiq(aula) {
    const response = await Axios().put(
      `aulas-eletivas/atualizar-registro-notifiq/${aula.id}`,
      aula
    );
    const aulaAtualizada = response.data;
    return new Aula(aulaAtualizada);
  }

  async deletar(aula) {
    const response = await Axios().delete(`aulas-eletivas/${aula.id}`);
    const aulaDeletada = response.data;
    return aulaDeletada;
  }

  async atualizarSituacao(aula, novaSituacao) {
    const response = await Axios().put(`aulas-eletivas/atualizarSituacao/${aula.id}`, {
      ...aula,
      situacao: novaSituacao,
    });
    const aulaAtualizada = response.data;
    return new Aula(aulaAtualizada);
  }

  async recuperar(aula) {
    return this.atualizarAula({ ...aula, deleted_at: null });
  }

  async justificativas() {
    const response = await Axios().get("aulas-eletivas/falta/justificativas");
    return response.data;
  }

  async listarAulasEmConflitos(aula_id) {
    const response = await Axios().get(`aulas-eletivas/listar-aulas-conflitos/${aula_id}`);
    return response;
  }

  async pesquisarAulasEmConflitos(data, horario_id, turma_id, aula_id) {
    const response = await Axios().get(
      `aulas-eletivas/verificar-aula-existente/${data}/${parseInt(horario_id, 10)}/${parseInt(
        turma_id,
        10
      )}/${aula_id}`
    );
    return response;
  }

  // async graficoDeAulasDaSemana(franquia_id) {
  //   const response = await Axios().get(`aulas-eletivas/grafico/aulasDaSemana/${franquia_id}`);
  //   return response;
  // }

  // async graficoDeAulasConfirmadasDaSemana(franquia_id) {
  //   const response = await Axios().get(`aulas-eletivas/grafico/aulasConfirmadasDaSemana/${franquia_id}`);
  //   return response;
  // }

  // async graficoDeAulasLancadasEConfirmadas(franquia_id) {
  //   const response = await Axios().get(`aulas-eletivas/grafico/aulasLancadasEConfirmadas/${franquia_id}`);
  //   return response;
  // }

  // async graficoDeAulasLancadasEConfirmadasDoMunicipio() {
  //   const response = await Axios().get(`aulas-eletivas/grafico/aulasLancadasEConfirmadasDoMunicipio`);
  //   return response;
  // }
}
export default new AulasEletivosService();
