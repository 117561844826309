<template>
  <div class="d-flex align-center">
    <template
      v-if="
        $constants.situacoesMatriculaCondition[
          removerEspaco(matricula.situacao ? matricula.situacao : '')
        ]
      "
    >
      <v-currency-field
        :value="value || null"
        hide-details
        :allow-negative="false"
        dense
        :min="min"
        :decimal-length="1"
        :max="max"
        ref="input"
        :outlined="true"
        :style="{
          'min-width': '60px !important',
          'max-width': '60px !important',
        }"
        :disabled="true"
      />
    </template>
    <template v-else>
      <v-currency-field
        @input="handleInput"
        :value="value || null"
        hide-details
        :allow-negative="false"
        dense
        :min="min"
        :max="max"
        :decimal-length="1"
        ref="input"
        :outlined="true"
        :style="{
          'min-width': '60px !important',
          'max-width': '60px !important',
        }"
        :disabled="disabled"
      />
      <v-btn
        v-show="value === null && value !== undefined && !disabled"
        icon
        small
        color="error"
        class="red lighten-5 ml-2"
        @click="emitNull"
      >
        <v-icon x-small>fa fa-close</v-icon>
      </v-btn>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: 10,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    matricula: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleInput(val) {
      if (val !== null && val !== undefined) {
        const notasSplit = val.toString();
        const arr = notasSplit.split(".");

        if (arr[0] !== "0") {
          if (arr[1] >= "0" && arr[1] <= "2") {
            val = `${arr[0]}.${0}`;
          } else if (arr[1] >= "3" && arr[1] <= "7") {
            val = `${arr[0]}.${5}`;
          } else if (arr[1] >= "8" && arr[1] <= "9") {
            const result = parseInt(arr[0], 10) + 1;
            val = `${result}.0`;
          }
        }

        val = parseFloat(val); // Convertendo para número

        if (val > this.max) {
          val = this.max;
        } else if (val < this.min) {
          val = this.min;
        }

        this.$emit("input", val);
        this.$emit("change", val);
      }
    },
    emitNull() {
      this.$emit("input", undefined);
      this.$emit("change", undefined);
      setTimeout(() => {
        this.$refs.input.data.formattedValue = "";
      }, 150);
    },
    removerEspaco(string) {
      return string.replace(/\s/g, "_");
    },
  },
};
</script>
