import Axios from "@/plugins/Axios";
import store from "@/store";
import GestaoDeEletivo from "@/Models/GestaoDeEletivo";
import Aula from "@/Models/AulasEletiva";

export class GestoesDeEletivosService {
  async syncAll(pageNumber, query) {
    const response = await Axios().get(`gestoes-de-eletivos?page=${pageNumber}&query=${query}`);

    // store.commit("GestoesDeAulas/setGestoesDeAulas", gestoesDeAulas);
    return response.data;
  }

  // nova rota
  async listarAulasDaGestao(gestaoDeEletivosId, pageNumber = 0, query = "") {
    const response = await Axios().post(
      `gestoes-de-eletivos/listar-aulas-da-gestao-eletivo?page=${pageNumber}&query=${query}`,
      {
        gestao_id: gestaoDeEletivosId,
      }
    );

    const aulas = response.data.gestaoDeAulaEletiva.aulas_eletivas.map((aulas) => new Aula(aulas));
    const data = response.data.aulas_eletivas;
    const payload = response.data;
    return { aulas, payload, data };
  }

  async listarAulasGestao(gestaoDeEletivosId) {
    try {
      const response = await Axios().post("gestoes-de-eletivos/listar-aulas-gestao-eletivos", {
        gestao_id: gestaoDeEletivosId,
      });
      const aulas = response.data.gestaoDeAulaEletiva.aulas_eletivas.map((aula) => new Aula(aula));
      const payload = response.data;

      return { aulas, payload };
    } catch (error) {
      throw new Error("Não foi possível gerar seu relatório.");
    }
  }

  async listarAulasIntervaloData(gestaoDeAulaId, intervalo) {
    const response = await Axios().post("gestoes-de-aulas/listar-aulas-intervalo-datas", {
      gestao_id: gestaoDeAulaId,
      intervalo,
    });
    const aulas = response.data.gestaoDeAula.aulas.map((aulas) => new Aula(aulas));
    const payload = response.data;
    return { aulas, payload };
  }

  async visualizar(gestaoDeEletivo) {
    const response = await Axios().get(`gestoes-de-eletivos/${gestaoDeEletivo}/visualizar`);
    return response.data;
    // store.commit("GestoesDeAulas/adicionarGestaoDeAula", novaGestaoDeAula);
    // return novaGestaoDeAula;
  }

  async visualizarParaPlano(gestaoDeAula) {
    const response = await Axios().get(`gestoes-de-aulas/${gestaoDeAula}/visualizar-para-plano`);
    return response.data;
    // store.commit("GestoesDeAulas/adicionarGestaoDeAula", novaGestaoDeAula);
    // return novaGestaoDeAula;
  }

  async gestaoGeralId(gestaoDeEletivosId) {
    const filtro = true;
    const response = await Axios().get(
      `gestoes-de-eletivos/${gestaoDeEletivosId}/visualizar/${filtro}`
    );
    return response.data;
    // store.commit("GestoesDeAulas/adicionarGestaoDeAula", novaGestaoDeAula);
    // return novaGestaoDeAula;
  }

  async geral() {
    const response = await Axios().get("gestoes-de-aulas/geral");
    const gestoesDeAulasGeral = response.data.map(
      (gestaoDeAulas) => new GestaoDeEletivo(gestaoDeAulas)
    );
    // store.commit("GestoesDeAulas/setGestoesDeAulas", gestoesDeAulasGeral);

    return gestoesDeAulasGeral;
  }

  async geralPaginate(pageNumber, query) {
    const response = await Axios().get(`gestoes-de-aulas/geral?page=${pageNumber}&query=${query}`);

    return response.data;
  }

  async listarGestoesProfessor(gestao) {
    const response = await Axios().post("gestoes-de-aulas/listar-gestoes-professor", {
      gestao,
    });
    return response.data;
  }

  async listarGestaoDeAulasDaTurma(turma_id) {
    const response = await Axios().post("gestoes-de-aulas/listar-gestoes-aulas-turma", {
      turma_id,
    });
    return response.data;
    // store.commit("GestoesDeAulas/adicionarGestaoDeAula", novaGestaoDeAula);
    // return novaGestaoDeAula;
  }

  async criar(relacoesList) {
    const response = await Axios().post("gestoes-de-eletivos", relacoesList);
    return response.data;
    // store.commit("GestoesDeAulas/adicionarGestaoDeAula", novaGestaoDeAula);
    // return novaGestaoDeAula;
  }

  async atualizar(gestaoDeEletivos) {
    const response = await Axios().put(
      `gestoes-de-eletivos/${gestaoDeEletivos.id}`,
      gestaoDeEletivos
    );
    const gestaoDeEletivosAtualizada = response.data;
    return gestaoDeEletivosAtualizada;
  }

  async deletarGestaoDeAula(gestaoDeAulas) {
    const response = await Axios().delete(`gestoes-de-aulas/${gestaoDeAulas.id}`);
    const gestaoDeAulasDeletada = response.data;
    // store.commit("GestoesDeAulas/deletarGestaoDeAula", gestaoDeAulas.id);
    return gestaoDeAulasDeletada;
  }

  async recuperarGestaoDeAula(gestaoDeAulas) {
    return this.atualizarGestaoDeAula({ ...gestaoDeAulas, deleted_at: null });
  }

  async listarTodosHorarios() {
    const response = await Axios().get(`gestoes-de-aulas/horarios-all/${null}`);
    return response.data;
  }
}
export default new GestoesDeEletivosService();
